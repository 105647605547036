let NODE_ENV = 'production';
// let NODE_ENV = 'demo';
// let NODE_ENV = 'local';


let Front_URL = "";
let baseUrl = "";
let BUSDAddress = "";
let BNBAddress = "";
let StakingContract = "";
let PriceProvider = "";
let BNBSYMBOL = "";
let toFixedSwap = "";
let toFixedBal = "";
let NetworkId = "";
let toFixed = "";
let networkName = "";
let toFixedvalue = "";
let txUrlAddress = "";
let txurl = "";
let tokenSymbol = "";
let AdminAddress = "";
let USDTAddress = "";
let DefiQToken = "";
let DefiqIDO = "";
let BnbRpcUrl = "";

if (NODE_ENV === "production") {
    Front_URL = 'https://defiq.live/';
    baseUrl = 'https://prodapi.defiq.live/';
    AdminAddress = "0x1866e0792B497BbC84D1e7c6366Eb3BB3C55a784";
    BUSDAddress = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";
    USDTAddress = "0x55d398326f99059ff775485246999027b3197955";
    DefiQToken = "0x866E7B8F76CF2435Fde81DEA9FB4693Ba002DF8F";//DefiQtoken
    DefiqIDO = "0xF5d3c026236d090192443E5E8825fdbc9f8Aa11d";
    // PriceProvider = "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526";
    txUrlAddress = "https://bscscan.com/address/";
    txurl = "https://bscscan.com/tx/";
    BnbRpcUrl = "https://bsc-dataseed.bnbchain.org";
    BNBAddress = "";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 56;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "DefiQ"
}
else if (NODE_ENV === "demo") {
    Front_URL = 'https://defiq-frontend.pages.dev/';
    baseUrl = 'https://defiqapi.wearedev.team/';
    AdminAddress = "0x0b35109baD98d8c6Fb7fA461eA584a7c58077aFC";
    BUSDAddress = "0x5648C735D27c0947ef458bAC3C70887e7B2eaEB8";
    USDTAddress = "0xd56202736a89c503D7ceDeE4acec278980c2C428";
    DefiQToken = "0xEE5a76bEC1f14247A7F153a3Af28345DB562d185";//DefiQtoken
    DefiqIDO = "0xB07b59F8C6482C77EB749f839AEf874b1BAFC3A8";
    // PriceProvider = "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BnbRpcUrl = "https://data-seed-prebsc-2-s2.bnbchain.org:8545";
    BNBAddress = "";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "DefiQ"
}
else {
    Front_URL = 'http://localhost:3000/';
    baseUrl = 'http://localhost:2053/'
    AdminAddress = "0x0b35109baD98d8c6Fb7fA461eA584a7c58077aFC";
    BUSDAddress = "0x5648C735D27c0947ef458bAC3C70887e7B2eaEB8";
    USDTAddress = "0xd56202736a89c503D7ceDeE4acec278980c2C428";
    // USDTAddress ="0x1f91503d61f96c3651f819dd14E32D37fEEf2BCd";  //6 dec
    DefiQToken = "0xEE5a76bEC1f14247A7F153a3Af28345DB562d185";//DefiQtoken
    DefiqIDO = "0xB07b59F8C6482C77EB749f839AEf874b1BAFC3A8";
    // DefiqIDO = "0xcF65E43C76A40b2D497aDb1E58A961F3636f504C"; //6 dec
    // PriceProvider = "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526";
    txUrlAddress = "https://testnet.bscscan.com/address/";
    txurl = "https://testnet.bscscan.com/tx/";
    BnbRpcUrl = "https://data-seed-prebsc-2-s2.bnbchain.org:8545";
    BNBAddress = "";
    BNBSYMBOL = 'BNB';
    toFixedSwap = 18;
    toFixedvalue = 8;
    NetworkId = 97;
    toFixed = 8;
    toFixedBal = 18;
    networkName = "Binance Chain";
    tokenSymbol = "DefiQ"
}

let key = {
    Front_URL: Front_URL,
    USDTAddress: USDTAddress,
    baseUrl: baseUrl,
    BnbRpcUrl:BnbRpcUrl,
    AdminAddress: AdminAddress,
    BUSDAddress: BUSDAddress,
    BNBAddress: BNBAddress,
    DefiQToken: DefiQToken,
    DefiqIDO: DefiqIDO,
    StakingContract: StakingContract,
    PriceProvider: PriceProvider,
    BNBSYMBOL: BNBSYMBOL,
    toFixedSwap: toFixedSwap,
    toFixedBal: toFixedBal,
    toFixedvalue: toFixedvalue,
    NetworkId: NetworkId,
    toFixed: toFixed,
    networkName: networkName,
    txUrlAddress: txUrlAddress,
    txurl: txurl,
    tokenSymbol: tokenSymbol
};

export default key;
