import React, { useEffect, useState } from "react";
import d1 from "../assets/images/d1.svg";
import d2 from "../assets/images/d2.svg";
import d3 from "../assets/images/d3.svg";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { getbuyhistory } from "../Action/ApiAction";
import { shortText } from "../helper/customMath";
import { getTimeAgo } from "../helper/dateformat";
import config from "../config/config";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const [historyList, sethistoryList] = useState([]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    getbuyList(1, 10);
  }, []);

  const getbuyList = async (skip, limit) => {
    let data = {
      skip: skip,
      limit: limit,
    };
    var { result } = await getbuyhistory(data);
    sethistoryList(result);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    centerMode: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var settings1 = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="overflow-hidden position-relative">
        <div className="full_bgsnows">
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>

          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
          <div class="snow"></div>
        </div>

        {/* <ScrollToTopOnMount /> */}
        <Navbar />
        <div data-aos="fade-up" data-aos-duration="1000">
          <div className="page_header">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/banner.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div
                  className="col-lg-6 m-auto"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="box">
                    <h1>DeFiQ</h1>
                    <p>A unique Crypto Project with Real-World Utility</p>
                    <Link to="/buytoken" className="primary_btn d-inline-block">
                      Buy Token <span className="round"></span>
                    </Link>
                    <a
                      // href={require("../assets/pdf/litepaper.pdf")}
                      href={`${config.baseUrl}pdf/litepaper.pdf`}
                      target="_blank"
                      className="primary_btn d-inline-block pdf"
                    >
                      Lite Paper <span className="round"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="about"
          name="about"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="500"
        >
          <div className="container">
            <div className="shade">
              <div className="aboutbg">
                <div
                  className="row"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  <div className="col-lg-6 m-auto">
                    <h2 className="h2tag mb-4">
                      About <span> DeFiQ</span>{" "}
                    </h2>
                    <p>
                      The project's primary goal is to create a seamless and
                      rewarding experience for consumers, retailers, and brands
                      alike, all while leveraging and the power of blockchain
                      technology
                    </p>
                    <p>
                      DefiQ Impact's platform is designed to simplify the
                      process of earning, managing, and redeeming rewards. The
                      traditional rewards systems are often plagued with issues
                      such as limited usability, complex redemption processes,
                      and the need to juggle multiple rewards programs.
                    </p>
                    <p className="mb-4 pb-3">
                      DefiQ Impact aims to address these pain points by offering
                      a single, unified rewards platform that is both
                      transparent and user friendly.
                    </p>
                    <button className="primary_btn blue">
                      Learn More<span className="round"></span>
                    </button>
                  </div>
                  <div className="col-lg-6 m-auto">
                    <img
                      src={require("../assets/images/about.png")}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature" name="feature">
          <div className="container">
            <div
              className="head pb-2"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2 className="h2tag">DeFiQ - Features</h2>
              <p>
                A UNIQUE CRYPTO PROJECT WITH REAL-WORLD UTILITY, TOKENOMICS,ALL
                IN ONE WALLET, SECURITY AND COMPATIBILITY, ASSETS SOLUTION
              </p>
            </div>
            <div className="row">
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <div className="box">
                  <div className="img_box">
                    <img src={d1} className="img-fluid" />
                  </div>
                  <h5 className="h5tag">SECURITY AND COMPATIBILITY</h5>
                  <p>
                    Beyond security the Ledger DeFiQ wallet excels in seamless
                    on-chain crypto interoperability, connecting to all major
                    applications. This enables secure access to popular dapps
                    like Open Sea, AAVE, and UniSwap directly through the
                    wallet. Additionally, the Ledger DeFiQ is compatible with
                    over 5,000 digital assets, NFTs, and more. The combination
                    of these features positions the Ledger DeFiQ wallet as the
                    best overall wallet for both security and compatibility.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="400"
              >
                <div className="box">
                  <div className="img_box">
                    <img src={d2} className="img-fluid" />
                  </div>

                  <h5 className="h5tag">ASSETS SOLUTION</h5>
                  <p>
                    DeFIQ self-custody wallet will have technological solutions
                    to enable users to have complete control over their funds
                    without having to shoulder the burden of managing their
                    keys. A single multi-chain wallet that supports almost all
                    major chains and can store most of the crypto assets is a
                    solution.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1100"
                data-aos-delay="700"
              >
                <div className="box">
                  <div className="img_box">
                    <img src={d3} className="img-fluid" />
                  </div>
                  <h5 className="h5tag">AUDIT</h5>
                  <p>TECH RATE, CERTIK</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="token" name="token">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag pb-3">Token Distributions</h2>
            </div>
            <ul
              className="mob_viewul d-lg-none d-block"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <li>
                <p>Liquidity</p>
                <h6>34%</h6>
              </li>
              <li>
                <p>Management Team</p>
                <h6>12%</h6>
              </li>
              <li>
                <p>Pre-Sale</p>
                <h6>32%</h6>
              </li>
              <li>
                <p>Legal & Advisor Team</p>
                <h6>12%</h6>
              </li>
              <li>
                <p>Airdrop</p>
                <h6>10%</h6>
              </li>
            </ul>
            <div
              className="outbox"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="500"
            >
              <div className="box">
                <div className="text-center img">
                  <img
                    src={require("../assets/images/token_v1.png")}
                    className="img-fluid"
                  />
                </div>
                <div className="blw_circle">
                  <p className="text-center mb-2 white">(Price Per Token)</p>
                  <h2 className="h2tag d-block text-center mb-5">$ 0.001</h2>
                  <div className="inbox">
                    <div className="inbox_bg">
                      <div>
                        <h5>10 Billion</h5>
                        <p>Total Supply</p>
                      </div>
                      <div>
                        <div className="center">
                          <h5>BEP 20</h5>
                          <p>Token Info</p>
                        </div>
                      </div>
                      <div className="ms-4 ps-2">
                        <h5>DeFiQ</h5>
                        <p>Name & Ticker</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrapper">
                  <div className="scanner"></div>
                </div>
                <video loop muted nocontrols autoPlay width={"100%"}>
                  <source
                    src={require("../assets/images/video2.mp4")}
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </section>
        <section className="platform" name="platform">
          <div className="container">
            <h2
              className="h2tag head d-block mb-5"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Platform Recent Activity
            </h2>
            <Slider
              {...settings}
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              {historyList &&
                historyList.length > 0 &&
                historyList.map((item) => {
                  return (
                    <div
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="300"
                    >
                      <div className="box">
                        <img
                          src={require("../assets/images/logo_dell.png")}
                          className="img-fluid"
                        />
                        <div className="flx">
                          <div className="inbox">
                            <h5 className="h5tag">Purchased Token</h5>
                            <p>{item.earnedAmt}</p>
                          </div>
                          <div className="inbox">
                            <h5 className="h5tag">User ID</h5>
                            <p>{shortText(item.address)}</p>
                          </div>
                        </div>
                        <a
                          href={config.txurl + item.transactionHash}
                          target="_blank"
                        >
                          <img
                            src={require("../assets/images/arw.png")}
                            className="img-fluid me-2"
                          />
                          {getTimeAgo(item.createdAt)}
                        </a>
                      </div>
                    </div>
                  );
                })}

              {/* <div data-aos="fade-up" data-aos-duration="1000"   data-aos-delay="300">
              <div className="box">
                <img src={require("../assets/images/logo_dell.png")} className="img-fluid" />
                <div className="flx">
                  <div className="inbox">
                    <h5 className="h5tag">Purchased Token</h5>
                    <p>50</p>
                  </div>
                  <div className="inbox">
                    <h5 className="h5tag">User ID</h5>
                    <p>DeFiQ 001</p>
                  </div>
                </div>
                <a><img src={require("../assets/images/arw.png")} className="img-fluid me-2" />5 hours </a>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000"   data-aos-delay="300">
              <div className="box">
                <img src={require("../assets/images/logo_dell.png")} className="img-fluid" />
                <div className="flx">
                  <div className="inbox">
                    <h5 className="h5tag">Purchased Token</h5>
                    <p>50</p>
                  </div>
                  <div className="inbox">
                    <h5 className="h5tag">User ID</h5>
                    <p>DeFiQ 001</p>
                  </div>
                </div>
                <a><img src={require("../assets/images/arw.png")} className="img-fluid me-2" />5 hours </a>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000"   data-aos-delay="300">
              <div className="box">
                <img src={require("../assets/images/logo_dell.png")} className="img-fluid" />
                <div className="flx">
                  <div className="inbox">
                    <h5 className="h5tag">Purchased Token</h5>
                    <p>50</p>
                  </div>
                  <div className="inbox">
                    <h5 className="h5tag">User ID</h5>
                    <p>DeFiQ 001</p>
                  </div>
                </div>
                <a><img src={require("../assets/images/arw.png")} className="img-fluid me-2" />5 hours </a>
              </div>
            </div> */}
            </Slider>
          </div>
        </section>
        <section className="roadmap" name="roadmap">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag mb-4">DeFiQ Roadmap</h2>
              {/* <img src={require("../assets/images/roadmap_v1.png")} className="img-fluid d-lg-block d-none" /> */}
            </div>
            <div data-aos="fade-up" data-aos-duration="1000">
              <Slider {...settings1}>
                <div>
                  <div className="box">
                    <div className="inbox">
                      <p>Phase 1</p>
                      <div className="title">Air Drop </div>{" "}
                      <span>May-2023</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <div className="inbox">
                      <p>Phase 2</p>
                      <div className="title">Defi Token Site Launch </div>{" "}
                      <span>Aug-2023</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <div className="inbox">
                      <p>Phase 3</p>
                      <div className="title">ICO Sale </div>{" "}
                      <span>Aug-2023</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <div className="inbox">
                      <p>Phase 4</p>
                      <div className="title">Listing on DEX </div>{" "}
                      <span>Jan 2024</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <div className="inbox">
                      <p>Phase 5</p>
                      <div className="title">Listing on CEX </div>{" "}
                      <span>Aug 2024</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <div className="inbox">
                      <p>Phase 6</p>
                      <div className="title">NFT's Launch </div>{" "}
                      <span>Feb 2025</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <div className="inbox">
                      <p>Phase 7</p>
                      <div className="title">Metaverse Soft Launch </div>{" "}
                      <span>Sep 2025</span>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="box">
                    <div className="inbox">
                      <p>Phase 8</p>
                      <div className="title">Shoping Cart </div>{" "}
                      <span>Oct 2025</span>
                    </div>
                  </div>
                </div>
              </Slider>

              {/* <div className="d-lg-none d-block" >
                <ul>
                  <li>Air Drop - May-2023</li>
                  <li>Website Launch - AUG 2023</li>
                  <li>ICO Sale - Aug 2023</li>
                  <li>Listing on DEX - Jan 2024</li>
                  <li>Listing on CEX - Aug 2024</li>
                  <li>NFT's Launch - Feb 2025</li>
                  <li>Metaverse Soft Launch - Sep 2025</li>
                  <li>Shoping Cart - Oct 2025 </li>
                </ul>
              </div> */}
            </div>
          </div>
        </section>
        <section className="faq" name="faq">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag">FAQ</h2>
            </div>
            <div class="accordion" id="accordionExample">
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    1. What is DeFiQ ?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      DeFIQ is self-custody wallet will have technological
                      solutions to enable users to have complete control
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    2. How to Buy DeFiQ Tokens?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      Connect your trust wallet or metamask wallet and type the
                      url on dap browser.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    3. Where will I receive my DeFiQ purchased Token?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      If you have purchase DeFiQ tokens on trustwallet you
                      receive in trust wallet, If you have purchased on metamask
                      you will receive in metamask wallet.
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="900"
              >
                <h2 class="accordion-header" id="headingfour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                  >
                    4. How to trade DeFiQ Tokens?
                  </button>
                </h2>
                <div
                  id="collapsefour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      {" "}
                      Once DeFiQ tokens are listed on exchanges you can start
                      trading DeFiQ tokens.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="accordion-item"
                data-aos="fade-in"
                data-aos-duration="1000"
                data-aos-delay="900"
              >
                <h2 class="accordion-header" id="headingfive">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefive"
                    aria-expanded="false"
                    aria-controls="collapsefive"
                  >
                    5. What is the benefit of staking DeFiQ tokens?
                  </button>
                </h2>
                <div
                  id="collapsefive"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingfive"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="para">
                      {" "}
                      Once the DeFiQ tokens started for utilization in various
                      applications the price of the tokens will make you 10
                      X to 20 X profit.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
